import { useState } from "react";
import './menus.css';
import { useNavigate } from "react-router-dom";

function T8_logistics({setactivity, permission}) {
  const [isProductionOpen, setProductionOpen] = useState(true); // Predeterminadamente abierto
  const navigate = useNavigate();
  return (
    <li className="menu-item">
          <div onClick={() => setProductionOpen(!isProductionOpen)} className="menu-title">
            <span role="img" aria-label="key">🚚</span> Operaciones
            <span className={`arrow ${isProductionOpen ? 'open' : ''}`}>▾</span>
          </div>
          {isProductionOpen && (
            <ul className="submenu">
              {permission === "1" ? <li onClick={() => navigate('/Despachos')}>Despachos</li> : null}
              
            </ul>
          )}
        </li>
  );
}

export default T8_logistics;
