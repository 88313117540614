import React from 'react';
import logo from '../assets/logo_solo.png';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { close_session } from '../utils/services';

function Header({usr_name, token}) {

  const navigate = useNavigate();

  const cerrar_sesion = ()=>{
    
    async function fetchClose_session() {

        await close_session(token);
        navigate("/");
   
    }
    fetchClose_session();
  }


  return (
    <header className="header">
      <div >
        <img src = {logo} alt='' width='110'/>
      </div>
      <p className="usr_name">{usr_name}</p>
      <button className="logout-button" onClick={cerrar_sesion}>Cerrar sesión</button>
    </header>
  );
}

export default Header;