import React, { useEffect, useRef, useState } from 'react';
import './Projects_relations.css';
import { add_project, get_projects } from '../../utils/services';
import {  useNavigate } from 'react-router-dom';

function Projects_relations  ({user_id, permission}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const modelInputRef = useRef(null);

  //LIST OF SERIAL MEMBERS:
  const [projects, setprojects] = useState([]);
  const [newName, setNewName] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  const handle_email_alias = (index, costo) => {
    setEditIndex(index);
    setNewName(costo);
  };

  const handle_update_email_alias = (memberId) => {
    handleUpdateEmail(memberId);
  };


  const handleUpdateEmail = (memberId) => {
    /*
    async function fetchUpdatemember() {
      console.log(memberId + " " + newEmail);
      const memberUpdate = await update_member(token, memberId, newEmail);
      if (memberUpdate==="INVALID"){
        navigate("/");
      }else{
        setEditIndex(null);
        refresh_members_list();
     }
    }
    fetchUpdatemember(memberId);
    */
  };


  const refresh_projects_list = () => {
    async function fetchCheckpoints() {
        const membersList = await get_projects(token);
        if (membersList==="INVALID"){
          navigate("/");
        }else{

          const members = membersList.map((element, index) => {
            return {id:element.project_id, name: element.project_name, country: element.country, link_token: element.link_token, count_serials: element.t8s, creation_date: element.creation_date, owner_mail: element.owner_mail, index: index };
          });

          setprojects(members);
                  
       }
      }
      fetchCheckpoints();
  }

  const selectproject = (index, id) => {
      navigate("/project?id=" + id);
  };

  
  useEffect(() => {
    console.log("Permission: " + permission);
    refresh_projects_list();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  
  const handleAddBt = () => {

 
    async function fetchAddModel(newProject) {
      
       
      const deviceMessages = await add_project(token, newProject);
      if (deviceMessages==="INVALID"){
        navigate("/");
      }else{
         refresh_projects_list();

     }
    }

    fetchAddModel(modelInputRef.current.value);
  };


  return (
    
     <div className="container">
    
     
      <div className="table-container2">
      <table className="version-table2">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Nombre</th>
              <th style={{ width: '15%' }}>Pais</th>
              <th style={{ width: '5%' }}># T8</th>
              <th style={{ width: '15%' }}>Dashboard</th>

            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {

                    return (
                        <tr
                          key={index}
                          onClick={permission === "1" ? () => selectproject(index, project.id) : null}
                        >
                     
                        <td>
                      
                          {project.name}
                      </td>
                    
                        <td>{project.country}</td>
                        <td>{project.count_serials}</td>
                        <td>
                            <a 
                              href={`https://lookerstudio.google.com/u/0/reporting/334f6121-5216-4e26-8827-0747b360d218/page/p_4t028esnmd?params=%7B%22ds3.project%22:%22${project.link_token}%22,%22ds3.project_name%22:%22${project.name}%22,%22ds0.country_%22:%22${project.country}%22,%22ds84.project%22:%22${project.link_token}%22,%22ds97.project_name%22:%22${project.name}%22,%22ds0.project_name%22:%22${project.name}%22,%22ds97.country_%22:%22${project.country}%22,%22ds0.project%22:%22${project.link_token}%22,%22ds120.project%22:%22${project.link_token}%22,%22ds12.project%22:%22${project.link_token}%22,%22ds97.project%22:%22${project.link_token}%22,%22ds68.project%22:%22${project.link_token}%22,%22ds70.project%22:%22${project.link_token}%22,%22ds72.project%22:%22${project.link_token}%22,%22ds74.project%22:%22${project.link_token}%22,%22ds88.project%22:%22${project.link_token}%22,%22ds89.project%22:%22${project.link_token}%22,%22ds94.project%22:%22${project.link_token}%22%7D`}
                            
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Dashboard
                            </a>
                          </td>

                    
                        </tr>
                      )
          
              })}
          </tbody>
        </table>
    </div>

    {permission === "1" ? 
              
              <div className='newProject'>

              <input  type="text" id="new_project" ref={modelInputRef} />
              <button onClick={handleAddBt}>Agregar</button>
              </div>
            : null}
    </div>
  );
};

export default Projects_relations;
