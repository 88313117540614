import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import TOMi8versions from './components/pages/TOMi8versions';
import Projects_relations from './components/pages/Projects_relations';
import Units_status from './components/pages/Units_status';
import { getProfile} from "./utils/services";
import './App.css';
import {  useNavigate } from 'react-router-dom';
import Despachos from './components/pages/Despachos';
import Checkpoints from './components/pages/talento/Checkpoints'
import Models_admin from './components/pages/Models_admin';
import Members_master from './components/pages/talento/Members_master';
import Project_admin from './components/pages/Project_admin';
import Dashboard from './components/pages/Dashboard';


function App({page}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  let [devices_management, setDevice_management] = useState(undefined);
  let [logistics, setlogistics] = useState(undefined);
  let [project_management, setproject_management] = useState(undefined);
  let [talent_metrics, settalent_metrics] = useState(undefined);
  let [user_admin, setuser_admin] = useState(undefined);
  let [accountant, setaccountant] = useState(undefined);
  let [usr_name, setusrname] = useState("");
  let [usr_id, setusrid] = useState("");
  let [current_activity, setactivity] = useState("Dashboard");


  
  useEffect(() => {
   
    if (page !== ""){
      setactivity(page);
    }
  }, [page]); // Solo se vuelve a ejecutar si 'token' cambia


  useEffect(() => {
    async function fetchProfile() {
      const profile = await getProfile(token);
      console.log("Perfil:" + profile);
      if (profile==="INVALID"){
        navigate("/");
      }else{
        setusrid(profile[0].id);
        setusrname(profile[0].usr_name);
        setDevice_management(profile[0].devices_management);
        setlogistics(profile[0].logistics);
        setproject_management(profile[0].project_management);
        settalent_metrics(profile[0].talent_metrics);
        setuser_admin(profile[0].user_admin);
        setaccountant(profile[0].accountant);
     }
    
   
    }

    fetchProfile();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

  return (
    <div className="App">
      <Header usr_name={usr_name} token={token}/>
      <div className="main-content">
        <Sidebar
          setactivity = {setactivity}
          devices_management={devices_management}
          logistics={logistics}
          project_management={project_management}
          talent_metrics={talent_metrics}
          user_admin={user_admin}
          accountant={accountant}
        />
        {current_activity === 'TOMi8versions' && <TOMi8versions />}
        {current_activity === 'Projects_relations' && <Projects_relations usr_id={usr_id} permission={project_management}/>}
        {current_activity === 'Units_status' && <Units_status usr_id={usr_id} permission={project_management}/>}
        {current_activity === 'Despachos' && <Despachos usr_id={usr_id}/>}
        {current_activity === 'Checkpoints' && <Checkpoints usr_id={usr_id}/>}
        {current_activity === 'Models_Admin' && <Models_admin  usr_id={usr_id} permission={devices_management}/>}
        {current_activity === 'Members_master' && <Members_master  usr_id={usr_id} permission={devices_management}/>}
        {current_activity === 'Project_admin' && <Project_admin  usr_id={usr_id} permission={project_management} />}
        {current_activity === 'Dashboard' && <Dashboard  usr_id={usr_id} permission={devices_management} />}
      </div>
    </div>
  );
}





export default App;
