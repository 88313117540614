import React, { useEffect, useRef, useState } from 'react';
import './Project_admin.css';
import { add_serial, delete_projects_permission, delete_projects_relation, get_brevo_folders, get_brevo_lists, get_project_permissions, get_project_serials, get_project_updt_status, insert_projects_permission, request_brevo_csv, update_project, validate_procces} from '../../utils/services';
import {  useNavigate, useSearchParams } from 'react-router-dom';
import Modal_proyectos from '../menus/Modal_proyectos_brevo';
import md5 from 'md5';

function Project_admin  ({usr_id, permission}) {

  const serialInputRef = useRef(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [currentfolderName, setcurrentfolderName] = useState('');
  const [num_seriales, set_num_serials] = useState(0);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Obtiene el valor de 'id'
  const [nombre_proy, setnombre_proy] = useState('');
  const [country, setcountry] = useState('');

  const [newName, setnewName] = useState('');
  const [newEmail, setnewMail] = useState('');
  

  const [inProgress, setInProgress] = useState(false);
  const [link_name, setlink_name] = useState('');
  const [link_id, setlink_id] = useState('');
  const [link_token, setlink_token] = useState('');
  const [BrevoElements, setBrevoElements] = useState('');
  const [CloudElements, setCloudElements] = useState('');

  const [ok_serials, set_ok_serials] = useState('');
  const [failed_serials, set_failed_serials] = useState('');
  const [num_seriales_procesados, set_procesados] = useState(0);

  let seriales;

  //LIST OF SERIAL MEMBERS:
  const [SerialList, setSerialList] = useState([]);
  const [folderPage, setFolderPage] = useState(true);

  const [permissions_users, setpermissions_users] = useState([]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalPermisos, setModalPermisos] = useState(false);
  const [folders_brevo, setfolders_brevo] = useState([]);
  const [projects_brevo, setprojects_brevo] = useState([]);

  const refresh_projects_list = (id) => {
    async function fetchCheckpoints() {
        const membersList = await get_project_serials(token, id);
        if (membersList==="INVALID"){
          navigate("/");
        }else{
          const members = membersList['serials'].map((element, index) => {
            return {id:element.id, serial: element.tomi_serial, sub_cat: element.sub_cat, index: index };
          });
          setSerialList(members);

          setnombre_proy (membersList[0].project_name);
          setcountry (membersList[0].country);
          setlink_token (membersList[0].link_token);
          setlink_id (membersList[0].link_list_id);
          setlink_name (membersList[0].link_project_name);

          //Valido cuantos usuarios hay en el dashboard y cuantos en brevo
          loadBrevo_status(membersList[0].link_list_id);
       
       }
      }
      fetchCheckpoints();
  }

  const countSerials =(e) =>{
    let seriales;
    seriales = e.split('\n');
    set_num_serials (seriales.length);

}


useEffect(() => {

  
  if (permission !== "1" ){
    console.log( "Permision: " + permission);
    navigate('/');
  }
}, [permission]); // Solo se vuelve a ejecutar si 'token' cambia


  
const btDelete_permission = (id_permission) => {
   

    async function fetchDeletePermission(id_permission) {
      const membersList = await delete_projects_permission(token, id_permission);
      if (membersList==="INVALID"){
        navigate("/");
      }else{
          load_permissions(id);
     }
    }
    fetchDeletePermission(id_permission);
};

const btAdd_permission = () => {
   

  async function fetchDeletePermission() {
    const membersList = await insert_projects_permission(token,newName, newEmail, id );
    if (membersList==="INVALID"){
      navigate("/");
    }else{
        load_permissions(id);
   }
  }
  fetchDeletePermission();
};

const btDelete = (id_relation) => {
   

  async function fetchDeleteRelation(id_relation) {
    const membersList = await delete_projects_relation(token, id_relation);
    if (membersList==="INVALID"){
      navigate("/");
    }else{
        refresh_projects_list(id);
   }
  }
  fetchDeleteRelation(id_relation);
};

const btUpdateProj = () => {
   

  async function fetchDeleteRelation() {
    const membersList = await update_project(token,id, nombre_proy, link_token, link_id, link_name, country);
    if (membersList==="INVALID"){
      navigate("/");
    }else{
        refresh_projects_list(id);
   }
  }
  fetchDeleteRelation();
};

  
  useEffect(() => {
    if (id>0){
      refresh_projects_list(id);
    }
  }, [token, id]); // Solo se vuelve a ejecutar si 'token' cambia


  
  const insertSerialClick = () => {

    async function fetchChangeStatus() {
      
      if ( serialInputRef.current.value === ""){
        alert("Faltan datos");
        return;
      }

      seriales = serialInputRef.current.value.split('\n');
      set_ok_serials("");
      set_failed_serials("");

      

      let count = 0;
      for (const serial of seriales) {
        count +=1;
        set_procesados(count);
        if (serial !== "") {


            let serials_sub_cat = serial.split(",");

            const deviceStatus = await add_serial(token, serials_sub_cat[0], (serials_sub_cat[1] ?? ""), id);

            if (deviceStatus !== "OK") {
                set_failed_serials(prevString => prevString + "\n " + serials_sub_cat[0]);
            } else {
              // 9 Type Logistics annotation in issue_type
              if (deviceStatus === "INVALID") {
                set_failed_serials(prevString => prevString + "\n " + serials_sub_cat[0] );
              } else {
                set_ok_serials(prevString => prevString + "\n " + serials_sub_cat[0]);
              }
            }
        }
      }

      refresh_projects_list(id); 
      }
  
      fetchChangeStatus();

  }


  
  const fill_linked_token = (name, token, id) => {
    setModalOpen(false);
    setlink_id(id);
    setlink_name(name);
    setlink_token(token);

};


const open_project_modal = (token) => {
  setModalOpen(true);
  loadBrevoFolders();

};

const open_modificar_permisos = (token) => {
  setModalPermisos(true);
  load_permissions(id);
};

const update_bigq_list = () => {
  setInProgress(true);
  setBrevoElements(0);
  setCloudElements(0); 
  async function fetchBrevoLists() {
    const csv_request_initial = await request_brevo_csv(token, link_id, link_token);
    if (csv_request_initial === "error") {
      navigate("/"); // Redirige si hay error
      return;
    }

    let timeout_counter = 0; // Contador de intentos
    const intervalo = setInterval(async () => {
      try {
        const process_status = await validate_procces(csv_request_initial);
        if (process_status === "finished") {
          setInProgress(false);
          console.log("Proceso completado");
          clearInterval(intervalo); // Detenemos el intervalo si está listo
          loadBrevo_status(link_id);
        } else if (timeout_counter >= 10) {
          console.log("Se alcanzó el tiempo límite");
          clearInterval(intervalo); // Detenemos el intervalo si supera el límite
        }
        timeout_counter++; // Incrementa el contador después de cada ciclo
      } catch (error) {
        console.error("Error en la validación del proceso:", error);
        clearInterval(intervalo);
      }
    }, 3000);
  }

  fetchBrevoLists(); // Inicia el proceso
};


const load_permissions = (id) => {

  async function fetchBrevoFolders() {
    const permissionList = await get_project_permissions(token, id);
      if (permissionList==="INVALID"){
        navigate("/");
      }else{
        const the_project_users = permissionList.map((element, index) => {
          return {id:element.id, email_id: element.email_id, usr_name: element.usr_name, index: index };
        });
        setpermissions_users(the_project_users);
                
   }
  }


    fetchBrevoFolders();

};




const loadBrevoFolders = () => {

  async function fetchBrevoFolders() {
    const foldersList = await get_brevo_folders(token);
      if (foldersList==="INVALID"){
        navigate("/");
      }else{
        const the_folders_brevo = foldersList.map((element, index) => {
          return {id:element.id, name: element.name, index: index };
        });
        setfolders_brevo(the_folders_brevo);
                
   }
  }


    fetchBrevoFolders();

};

const loadFolderLists = (listid) =>{

  async function fetchBrevoLists() {
    const projectsList = await get_brevo_lists(token, listid);
    if (projectsList==="INVALID"){
      navigate("/");
    }else{
      const the_projects_brevo = projectsList.map((element, index) => {
        let splitedName = element.name.split("-");

        return {id:element.id, name: element.name, link_token: md5(splitedName[0]), users: element.uniqueSubscribers , index: index };
      }).sort((a, b) => a.name.localeCompare(b.name));;
      setprojects_brevo(the_projects_brevo);
              
   }
  }

  fetchBrevoLists();
};


const loadBrevo_status = (link_list_id) =>{

  async function fetchBrevoStatus(link_list_id) {
    const list_update_status = await get_project_updt_status(token, link_list_id);
          if (list_update_status==="INVALID"){

          }else{
            setBrevoElements(list_update_status['uniqueInBrevo']);
            setCloudElements(list_update_status['bigQelements']);            
          }
  }

  fetchBrevoStatus(link_list_id);
};


const load_folderlists= (listid, foldername) => {
  setcurrentfolderName(foldername);
  loadFolderLists(listid);
  setFolderPage(false);
  
};

const handleGoBack= (listid) => {
  setFolderPage(true);
  
};





  return (
    <div className="containerChk">
        <div className="left-panel2">
        <div className="field-container">
        <label htmlFor="id">ID:</label>
        <span>{id}</span>
        </div>

        <div className="field-container">
        <label htmlFor="nombre">Nombre:</label>
        <input type="text" id="nombre" value={nombre_proy} onChange={(e) => setnombre_proy(e.target.value)} />
        </div>

        <div className="field-container">
        <label htmlFor="link-token">Lista de Brevo:</label>
          <input type="text" id="link-token"  value={link_name}  readOnly/>
          <label htmlFor="link-token">En Brevo: {BrevoElements}   En el dashboard: {CloudElements} </label>
          <button className={`btn-update ${inProgress ? "btn-update-progress" : "btn-update"}`}
           onClick={() => update_bigq_list()}
           disabled={inProgress}
           > {inProgress ? "Actualizando..." : "Actualizar"}</button>
          <button className="btn-browse" onClick={() => open_project_modal()}>Cambiar</button>
         
         
          <Modal_proyectos isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <h1>Selecciona una lista de Brevo</h1>
                {!folderPage ?
                  <a  href='#' 
                  onClick={(e) => {
                    e.preventDefault(); // Previene la recarga de la página
                    handleGoBack(); // Llama a tu función personalizada
                  }} > ⬅️ {currentfolderName} </a>
                  :null
                }
                {!folderPage ?
                    <div className="table-container3">
                      <table className="version-table3">
                          <thead>
                            <tr>
                              <th style={{ width: '45%' }}>Nombre</th>
                              <th style={{ width: '45%' }}>link_token</th>
                              <th style={{ width: '5%' }}># Users</th>

                            </tr>
                          </thead>
                          <tbody>
                            {projects_brevo.map((project, index) => {

                                    return (
                                        <tr
                                          key={index}
                                          onClick={() => fill_linked_token(project.name, project.link_token, project.id)}
                                        >
                                    
                                        <td>{project.name}</td>
                                        <td>{project.link_token}</td>
                                        <td>{project.users}</td>
                                    
                                        </tr>
                                      )
                          
                              })}
                    </tbody>
                    </table>
                    </div>
                    :
                    
                    <div className="table-container3">
                    <table className="version-table3">
                        <thead>
                          <tr>
                            <th style={{ width: '100%' }}>Carpeta</th>
                          </tr>
                        </thead>
                        <tbody>
                          {folders_brevo.map((folder, index) => {

                                  return (
                                      <tr
                                        key={index}
                                        onClick={() => load_folderlists(folder.id, folder.name)}
                                      >
                                  
                                      <td>{folder.name}</td>
                                  
                                      </tr>
                                    )
                        
                            })}
                  </tbody>
                  </table>
                  </div>
                }

               
         
          </Modal_proyectos>
        </div>



        <div className="field-container">
        <label htmlFor="pais">País:</label>
        <input type="text" id="pais"  value={country} onChange={(e) => setcountry(e.target.value)} />
        </div>

        <div className="field-container">
          <label htmlFor="pais">Permisos:</label>
          <button className="btn-browse" onClick={() => open_modificar_permisos()}>Modificar</button>
          <a 
                              href={`https://lookerstudio.google.com/u/0/reporting/334f6121-5216-4e26-8827-0747b360d218/page/p_4t028esnmd?params=%7B%22ds3.project%22:%22${link_token}%22,%22ds3.project_name%22:%22${nombre_proy}%22,%22ds0.country_%22:%22${country}%22,%22ds84.project%22:%22${link_token}%22,%22ds97.project_name%22:%22${nombre_proy}%22,%22ds0.project_name%22:%22${nombre_proy}%22,%22ds97.country_%22:%22${country}%22,%22ds0.project%22:%22${link_token}%22,%22ds12.project%22:%22${link_token}%22,%22ds97.project%22:%22${link_token}%22,%22ds68.project%22:%22${link_token}%22,%22ds120.project%22:%22${link_token}%22,%22ds70.project%22:%22${link_token}%22,%22ds72.project%22:%22${link_token}%22,%22ds74.project%22:%22${link_token}%22,%22ds88.project%22:%22${link_token}%22,%22ds89.project%22:%22${link_token}%22,%22ds94.project%22:%22${link_token}%22%7D`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Dashboard
            </a>
          <Modal_proyectos isOpen={isModalPermisos} onClose={() => setModalPermisos(false)}>
          <h1>Modifica los permisos</h1>
          <input type="text" id="nombre" placeholder="Nombre del contacto"  value={newName} onChange={(e) => setnewName(e.target.value)} />
          <input type="text" id="email"  placeholder="Email de google" value={newEmail} onChange={(e) => setnewMail(e.target.value)} />
          <button className="btn-cambiar" onClick={() => btAdd_permission()}>Agregar</button>

          <div className="table-permission">
                      <table >
                          <thead>
                            <tr>
                              <th style={{ width: '45%' }}>Nombre</th>
                              <th style={{ width: '45%' }}>email</th>
                              <th>Eliminar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {permissions_users.map((project, index) => {
                                    return (
                                        <tr>
                            
                                        <td>{project.usr_name}</td>
                                        <td>{project.email_id}</td>
                                        <td>
                                            <button
                                              onClick={() => { btDelete_permission(project.id); }}
                                              style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'red',
                                                cursor: 'pointer',
                                                fontSize: '1.5rem'
                                              }}
                                            >
                                              ❌
                                            </button>
                                          </td>
                                        </tr>
                                      )
                          
                              })}
                      </tbody>
                      </table>
                    </div>

          </Modal_proyectos>
        </div>

        <div className="button-container">
        <button className="add-cards-btn" onClick={btUpdateProj}>Guardar</button>
        </div>



        <label >serial o serial,sub_cat por linea </label>
        <textarea
        id="serial"
        rows="8"
        cols="50"
        onChange={(e) => countSerials(e.target.value)}
        ref={serialInputRef}
        />
        <label htmlFor="serial">Seriales por agregarse: {num_seriales}</label>
        <div className="field-container">
            <label> Procesados #: {num_seriales_procesados} </label>
          </div>
          <div className="field-container">
            <label> Seriales Registados:  {ok_serials} </label>
          </div>

          <div className="field-container">
            <label> Seriales fallidos: {failed_serials} </label>
            </div>

        <div className="button-container">
        <button className="add-cards-btn" onClick={insertSerialClick}>Agregar seriales al proyecto</button>
        </div>
        </div>
        <div className="right-panel2">
                
                <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>Sub_cat</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {SerialList.map((serial, index) => (
                  <tr key={serial.id}>
                    <td>{serial.serial}</td>
                    <td>{serial.sub_cat}</td>
                    <td>
                      <button
                        onClick={() => { btDelete(serial.id); }}
                        style={{
                          background: 'none',
                          border: 'none',
                          color: 'red',
                          cursor: 'pointer',
                          fontSize: '1.5rem'
                        }}
                      >
                        ❌
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


        </div>
    </div>
  );
};

export default Project_admin;
